import BaseModel from './base.model';
import PlanApi from '@/api/plan.api';

export default class Plan extends BaseModel {

  getModelName() {
    return 'Plan';
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {
    const json = this.toJSON(data);

    return PlanApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    return PlanApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const { id } = this;

    return PlanApi.remove(id);
  }
}
