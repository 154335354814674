<template>
  <div class="Modal icon-search">
    <div class="Modal-Header">
      <h1>{{$t('shared_icon-search.select_icon')}}</h1>
      <i
        class="Icon-ModalsClose"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="Modal-wrap">
      <!-- <label class="Label">{{$t('shared_icon-search.search_icons')}}
        <input
          ref='search'
          @input="search = $event.target.value"
          class="Input-100"
          type="search"
          name="search"
        >
      </label> -->
      <div class="ModalBody">
        <div
          :class="{'icons-list-container':1, 'icons-list-container--focus': hasFocus}"
          ref="container"
          tabindex="0"
          @keydown="unboundOnKeyDown"
          @focus="hasFocus = true"
          @blur="hasFocus = false"
        >

          <div class="icons-list">
            <i
              @click.prevent="chooseIcon(i.name)"
              v-for="i in icons"
              :key="i.name"
              :class="i.classes"
              :title="i.name"
              ref="icon"
            >{{i.name}}</i>
          </div>
        </div>
      </div>
    </div>
    <div class="ModalFooter">

      <!-- Regular bar -->
      <button-bar
        :label-confirm="$t('shared_icon-search.confirm_icon')"
        @cancel="$emit('close')"
        @confirm="confirm"
      >
        <div class="current-icon" tabindex="-1">
          <i v-show="icon" :class="`Icon-Thumb ${iconClasses}`">{{icon}}</i>
        </div>
      </button-bar>
    </div>
  </div>
</template>

<script>
import iconNames from '@/constants/material-icon-names';

export default {
  name: 'IconSearch',

  data() {
    return {
      icon: this.value,
      focusedIndex: iconNames.indexOf(this.value),
      numIconsInRow: 14,
      hasFocus: false,
      search: '',
    };
  },

  props: {
    value: String,
    emit: {
      type: Function,
      default: () => {},
    },

    iconClasses: {
      type: String,
      default: '',
    },
  },
  computed: {
    searchRegex() {
      return new RegExp('.*' + this.search.replace(/[^a-z-]/gi, '') + '.*', 'i');
    },
    icons() {
      let extraClasses = this.iconClasses.split(/\s+/).reduce((A, c) => (A[c] = true, A), {});

      return iconNames
      .filter(i => i.match(this.searchRegex))
      .map((i, idx) => ({
        name: i,
        isFocused: idx === this.focusedIndex,
        classes: {
          ...extraClasses,
          Icon: 1,
          'Icon-Modal': 1,
          'Icon--original': this.value === i,
          'Icon--picked': this.icon === i,
          'Icon--focused': this.hasFocus && this.focusedIndex === idx,
        },
      }));
    },
    onKeyDown() {
      return this.unboundOnKeyDown.bind(this);
    },
  },
  mounted() {
    try {
      const icon = this.$el.querySelector('.Icon:first-child');
      this.iconSize = icon.offsetWidth + parseFloat(getComputedStyle(icon).marginLeft) * 2;
      this.numIconsInRow = Math.floor(icon.parentElement.offsetWidth / this.iconSize);
    }
    catch {
      // ignore errors, just keep defaults.
    }

    // this.$el.ownerDocument.addEventListener('keydown', this.onKeyDown);

    if (this.value) {
      let picked = this.$refs.container.querySelector('.Icon--picked');
      this.scrollToIcon(picked);
    }
    this.$refs.container.focus();
  },
  beforeDestroy() {
    // this.$el.ownerDocument.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    unboundOnKeyDown(event) {
      switch (event.key) {
        case 'ArrowLeft':
          if (--this.focusedIndex < 0) {
            this.focusedIndex = 0;
          }
          event.preventDefault();
          break;
        case 'ArrowRight':
          if (++this.focusedIndex >= iconNames.length) {
            this.focusedIndex = iconNames.length - 1;
          }
          event.preventDefault();
          break;
        case 'ArrowUp':
          if ((this.focusedIndex -= this.numIconsInRow) < 0) {
            this.focusedIndex = 0;
          }
          event.preventDefault();
          break;
        case 'ArrowDown':
          if ((this.focusedIndex += this.numIconsInRow) >= iconNames.length) {
            this.focusedIndex = iconNames.length - 1;
          }
          event.preventDefault();
          break;
        case 'Enter':
          if (this.focusedIndex) {
            this.chooseIcon(iconNames[this.focusedIndex]);
          }
          event.preventDefault();
          break;
        default:
          // this.$refs.search.dispatchEvent(new Event(event));
          return;
      }
      const picked = this.$refs.icon[this.focusedIndex];

      if (!this.isIconVisible(picked)) {
        this.scrollToIcon(picked);
      }
    },
    scrollToIcon(iconElt) {
      if (!iconElt) {
        return;
      }
      this.$refs.container.scrollTo(
        0,
        iconElt.offsetTop - 2 * this.iconSize,
      );
    },
    isIconVisible(icon) {
      return (icon.offsetTop - this.iconSize > this.$refs.container.scrollTop)
      && (icon.offsetTop - 2 * this.iconSize) < (this.$refs.container.scrollTop + this.$refs.container.offsetHeight);
    },
    chooseIcon(icon) {
      this.icon = icon;
      this.focusedIndex = iconNames.indexOf(icon);
    },
    confirm() {
      this.$parent.$emit('input', this.icon);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.Modal.icon-search {
  margin: 0 auto;
  user-select: none;
  .Modal-wrap {
    flex-direction: column;
    padding: $spacingXL;
  }

  .icons-list-container {
    max-height: (30px * 12);
    overflow: auto;
  }

  .ButtonBar {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .icons-list {
    .Icon {
      width: 26px;
      height: 26px;
      overflow:hidden;
      margin: $spacingXS;
      font-size: 26px;
      cursor: pointer;
    }
    .Icon--picked {
      color: $colorPrimary;
    }
  }

  .current-icon {
    display: flex;
    margin: -1rem 0 0;
    background: $colorBlackFaded3;
    border-radius: $borderRadiusS;
  }
}
</style>
