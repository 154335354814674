<script>
import { mapState } from 'vuex';
import IntegrationApi from '@/api/integration.api';
import IntegrationTypes from '@/constants/integration-types';

export default {
  name: 'IntegrationPage',

  data() {
    return {
      integration: null,
      IntegrationTypes,
      type: null,
      isLoading: false,
      isConnecting: false,
      isDisconnecting: false,

      // Xero-specific
      accounts: [],
      accountOptions: [],
      accountCode: null,
      selectedAccountCode: null,
      tenants: [],
    };
  },

  computed: {
    ...mapState({
      user: state => state.session.user,
    }),
  },

  created() {
    this.type = this.$route.params.type;
    this.loadIntegration();
    this.processAction();
  },

  metaInfo() {
    if (this.type && this.IntegrationTypes[this.type]) {
      return {
        title: `${this.IntegrationTypes[this.type]} ${this.$t('global.integration')} | Controlla`
      };
    }

    return {
      title: `${this.$t('integration.title')} | Controlla`
    };
  },

  methods: {
    async loadIntegration() {
      this.isLoading = true;
      this.integration = await IntegrationApi.findByType(this.type);
      this.isLoading = false;

      if (this.type === 'xero') {
        this.accounts = this.integration?.subclassPublic?.availableAccounts || [];
        this.accountOptions = this.accounts.map(({ code, name }) => ({
          value: code,
          label: `${code} - ${name}`,
        }));
        this.accountCode = this.integration?.subclassPublic?.accountCode;
        this.tenants = this.integration?.subclassPublic?.availableTenants || [];
        this.tenantId = this.integration?.subclassPublic?.tenantId;
      }
    },

    async connect() {
      this.isConnecting = true;

      await this.integration.connect()
        .catch(error => {
          this.isConnecting = false;
          throw error;
        });
    },

    async disconnect() {
      this.isDisconnecting = true;
      await this.integration.disconnect()
      await this.loadIntegration()
      this.isDisconnecting = false;
      this.integration.isConnected = false;
    },

    async chooseXeroSetting() {
      if (!this.selectedAccountCode) {
        /// error
      }
      else {
        this.accountCode = this.selectedAccountCode;
      }
      await IntegrationApi.update(this.integration.id, {
        subclassPublic: {
          ...this.integration.subclassPublic,
          accountCode: this.accountCode,
        },
      }).then((integration) => {
        this.integration = integration;
      });
    },

    async processAction() {
      const {
        $route: {
          query: { status },
          params: { type },
        },
        user,
      } = this;

      // Coming back from a success page
      if (status == 'connected') {
        switch (type) {
          case 'dnsimple':
          case 'webflow':
          case 'digitalocean':
            this.user.experiences.asset_created = true;
            await this.user.updateOwn(user);
            break;
          case 'xero':
            this.user.experiences.xero_connected = true;
            await this.user.updateOwn(user);
            break;
        }
      }
    },
  },
};
</script>

<template>
  <div class="Page">
    <div class="InnerPage-Wrap--integration">
      <div class="Content--mediumWidth">
        <router-link class="settingsReturnLink" to="/integrations">
          <i class="Icon-Base">keyboard_backspace</i>{{ $t('global.installed_apps')}}
        </router-link>

        <div v-if="isLoading"><spinner class="Spinner--general" /></div>
        <div v-else>
          <h1 v-if="integration && integration.isConnected" class="Title">
            {{ IntegrationTypes[type] }} {{ $t('global.integration') }}
          </h1>
          <h1 v-else class="Title">
            {{ $t('integrations_integration.connect_your') }} {{ IntegrationTypes[type] }}
          </h1>

          <div v-if="type === 'xero'">

            <!-- Connected -->
            <div v-if="integration && integration.isConnected">
              <p>
                {{ $t('integrations_integration.you_are_connected_to') }}{{ IntegrationTypes[type] }} {{ $t('integrations_integration.with_the_account') }} <strong>{{ integration.connectedAs }}</strong
                >.
              </p>

              <p>
                {{ $t('integrations_integration.clients_imported_from_xero_appear_in') }} <router-link to="/directory/clients">{{ $t('global.client_directory') }}</router-link>.
              </p>

              <div v-if="!accountCode" class="Form-Wrap">
                <div class="Message-Warning">
                  <i class="Icon-Base--warning">error_outline</i> {{ $t('integrations_integration.select_xero_account_to_link') }}:
                </div>
                <div class="Form-Wrap">
                  <select-box
                    :model="selectedAccountCode"
                    :options="accountOptions"
                    @change="selectedAccountCode = $event.value"
                  />

                  <button
                    :disabled="!this.accountCode && !this.selectedAccountCode"
                    class="Button--primary"
                    @click="chooseXeroSetting"
                  >
                  {{ $t('integrations_integration.select_account') }}
                  </button>
                </div>
              </div>

              <div v-if="tenants.length && accountCode" class="Message-Info">
                <div class="Message-Header--compact">
                  <i class="Icon-Base--info">info</i>
                  {{ $t('integrations_integration.invoices_will_be_shared_with_with_xero_using_account_code') }}: <span class="account_code_highlighter">{{ accountCode }}</span>
                </div>
              </div>

              <div class="SpacerXL"></div>
              <div class="SpacerXL"></div>

            <div class="ActionPanel-Wrap">
              <button
                @click="disconnect"
                :disabled="isDisconnecting"
                class="Button--danger"
              >
              <i class="Icon-Base">block</i> {{ $t('global.disconnect') }}
                <spinner v-if="isDisconnecting"></spinner>
              </button>
              <div class="SpacerL"></div>
              <div class="Message-Danger">
                <div class="Message-Header--compact">
                  <i class="Icon-Base--danger">error_outline</i>
                  {{$t('integrations_integration.disconnecting_wont_delete')}}
                </div>
              </div>
              <div class="SpacerXL"></div>
            </div>
          </div>

            <!-- Not connected -->
            <div v-else class="GroupSet">
              <p>
                {{$t('integrations_integration.connect_your')}} {{ IntegrationTypes[type] }} {{$t('integrations_integration.account_to_sync_your')}}
                {{ IntegrationTypes[type] }} {{$t('integrations_integration.customers_with_controlla_as_clients')}}.
              </p>

              <div class="Message-Info">
                <div class="Message-Header--compact">
                  <i class="Icon-Base--info">info</i> 
                  {{$t('integrations_integration.connecting_xero_syncronizes_clients_creates_invoices')}}
                </div>
              </div>
              <div class="SpacerXL"></div>
              <div class="SpacerXL"></div>
              <button
                @click="connect"
                :disabled="isConnecting"
                class="Button--primary"
              >
                {{ $t('global.connect') }} {{ IntegrationTypes[type] }}
                <spinner v-if="isConnecting"></spinner>
              </button>
              <div class="SpacerXL"></div>
            </div>
          </div>

          <div v-else>
            <!-- Connected -->
            <div v-if="integration && integration.isConnected" class="GroupSet">
              <p>
                {{$t('integrations_integration.you_are_connected_to')}} <strong>{{ IntegrationTypes[type] }}</strong> {{$t('integrations_integration.with_the_account')}}
                 <strong>{{ integration.connectedAs }}</strong>.
              </p>

              <div v-if="['webflow', 'dnsimple', 'digitalocean'].includes(type)" class="Message-Info">
                <div class="Message-Header--compact">
                  <i class="Icon-Base--info">info</i>
                  {{$t('integrations_integration.items_imported_from')}} {{ IntegrationTypes[type] }} {{$t('integrations_integration.will_show_in_the')}}
                  <router-link :to="{ path: '/workspace/assets' }">{{$t('global.assets')}}</router-link> {{$t('global.section')}}
                </div>
              </div>

              <div v-if="['stripe'].includes(type)" class="Message-Info">
                <div class="Message-Header--compact">
                  <i class="Icon-Base--info">info</i>
                  {{$t('integrations_integration.you_can_add_credit_cards_using_your')}} {{ IntegrationTypes[type] }} {{$t('integrations_integration.via_the')}}
                  <router-link :to="{ path: '/billing/credit-cards' }">{{$t('global.credit_cards')}}</router-link> {{$t('global.section')}}
                </div>
              </div>
              <div class="SpacerXL"></div>
              <div class="SpacerXL"></div>
              <h2 class="DangerZone-Header">{{ $t('global.danger_zone') }}</h2>
              <div class="ActionPanel-Wrap">
                <div class="ActionPanelTop-Wrap">
                  <div class="ActionPanelText-Wrap">
                    <h3>{{$t('integrations_integration.disconnect_this_app')}}</h3>
                    <div class="Description"><i class="Icon-Base--danger">error_outline</i> {{$t('integrations_integration.disconnecting_wont_delete')}}</div>
                  </div>
                  <button
                    @click="disconnect"
                    :disabled="isDisconnecting"
                    class="Button--danger"
                    >
                    <i class="Icon-Base">block</i> {{ $t('global.disconnect') }}
                    <spinner v-if="isDisconnecting"></spinner>
                  </button>
              </div>
            </div>
            </div>

            <!-- Not connected -->
            <div v-else class="GroupSet">
              <p v-if="type === 'webflow'">
                {{$t('integrations_integration.connect_your')}}  {{ IntegrationTypes[type] }}  {{$t('integrations_integration.webflow_account')}}
              </p>

              <p v-if="type === 'dnsimple'">
                {{$t('integrations_integration.connect_your')}} {{ IntegrationTypes[type] }} {{$t('integrations_integration.dnsimple_account')}}
              </p>

              <p v-if="type === 'digitalocean'">
                {{$t('integrations_integration.connect_your')}} {{ IntegrationTypes[type] }} {{$t('integrations_integration.digitalocean_account')}}
              </p>

              <p v-if="type === 'stripe'">
                {{$t('integrations_integration.stripe_briefing')}}
              </p>

              <div class="Message-Info" v-if="!(IntegrationTypes[type] === 'Stripe') ">
                <div class="Message-Header--compact">
                  <i class="Icon-Base--info">thumb_up</i> 
                  {{$t('integrations_integration.connecting_will_not_makes_changes_to_service')}} 
                  {{ IntegrationTypes[type] }} {{$t('integrations_integration.accounts')}}
                </div>
              </div>
              <div class="Message-Info"  v-if="(IntegrationTypes[type] === 'Stripe') ">
                <div class="Message-Header--compact">
                  <i class="Icon-Base--info">info</i>
                  {{$t('integrations_integration.connecting_stripe_enables_payments')}}
                </div>
              </div>
              <div class="SpacerXL"></div>
              <div class="SpacerXL"></div>
              <button
                @click="connect()"
                :disabled="isConnecting"
                class="Button--primary Button--integration"
              >
              {{$t('global.connect')}} {{ IntegrationTypes[type] }}
                <spinner v-if="isConnecting"></spinner>
              </button>
              <div class="SpacerXL"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.account_code_highlighter {
  background: $colorWhiteFaded2;
  padding: 0.1rem 0.3rem;
  margin: -0.1rem 0;
  border-radius: $borderRadiusS;
  font-weight: $fontWeightBold;
}

</style>
