<script>
import { mapState } from 'vuex';
import User from '@/models/user.model';
import UserApi from '@/api/user.api';
import PlanApi from '@/api/plan.api';
import UserEdit from './modals/user-edit';
import UserInvite from './modals/user-invite';
import RoleTypes from '@/constants/role-types';
import ModalRemove from '@/components/shared/modal-remove';
import ModalArchive from '@/components/shared/modal-archive';

export default {
  data() {
    return {
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,
      users: [],
      allUsersLimit: 2,
      freeUsersLimit: 2,
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
    }),

    hasItems() {
      return this.users.length > 0;
    },

    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },

    justOneAdmin() {
      return this.users.filter(u => u.name === 'Admin').length === 1;
    },

    allUsersLimitReached() {
      return this.users.length >= this.allUsersLimit;
    },

    freeUsersLimitReached() {
      return this.users.length >= this.freeUsersLimit;
    }
  },

  created() {
    this.setupFilter();
    this.setupPage();
    this.loadPlanLimits();
    this.loadUsers();
    this.processAction();
  },

  methods: {
    async loadPlanLimits() {
      if (!this.account.plan) { // on trial, use defaults
        return;
      }

      const plan = await PlanApi.findById(this.account.plan);
      this.allUsersLimit = plan.limits?.allUsers || 9999;
      this.freeUsersLimit = plan.limits?.freeUsers || 2;
    },

    invite() {
      const user = new User();

      const onSave = model => {
        return user
          .invite(model)
          .then(() => {
            this.$notice.show('notices.user_invited');
            return this.loadUsers(true);
          })
          .catch(() => {
            this.$notice.show('notices.duplicate_email');
          });
      };

      this.$modal.show(
        UserInvite,
        { user, onSave, RoleTypes, freeUsersLimitReached: this.freeUsersLimitReached },
        { maxWidth: 500 },
        {
          opened: () => this.$router.push({ name: 'users', params: { action: 'invite' }}),
          closed: () => this.$router.replace({ name: 'users' }),
        }
      );
    },

    setupFilter() {
      this.filter = this.$filter.get('users');

      this.filter.setDefaults({
        search: '',
        sort: 'name',
        type: null,
        from: null,
        to: null,
        by: '',
        isArchived: false,
      });

      this.filter.loadValues(this.$route.query);

      this.filter.onChange(() => this.loadPage(1));
    },

    setupPage() {
      const { filter } = this;
      this.$store.dispatch('page/setup', { filter });
    },

    async processAction() {
      const { action, id } = this.$route.params;

      if (action === 'edit' && id) {
        const user = await UserApi.findById(id);
        if (user) {
          this.edit(user);
        }
      }
    },

    edit(user, isEdit = true) {
      const onSave = model => {
        return user
          .save(model)
          .then(() => this.onSaved(isEdit))
          .catch(err => {
            if (err.message.match(/last_admin/i)) {
              this.$notice.show('notices.last_admin');
            }
            else {
              this.$notice.show(err);
            }
          });
      };

      // only show remove option if it's only an invited (not accepted) user
      const onRemove = !user.inviteAccepted ? () => {
        return user
          .remove()
          .then(() => this.onRemoved(user))
          .catch(console.error);
      } : null;

      this.$modal.show(
        UserEdit,
        { user, isEdit, onSave, onRemove },
        { maxWidth: 500 },
        {
          opened: () => this.$router.push({ name: 'users', params: { action: 'edit', id: user.id }}),
          closed: () => this.$router.replace({ name: 'users' }),
        }
      );
    },

    remove(user) {
      const onRemove = () => user.remove().then(this.onRemoved);

      this.$modal.show(ModalRemove, {
        singular: 'user',
        onRemove,
        text: `${this.$t('confirmation.confirm_delete_user')} ${user.name ? `<strong>${user.name}</strong>` : ''}?`,
      });
    },

    onRemoved() {
      this.$notice.show('notices.user_removed');
      this.loadUsers(true);
    },

    archive(user) {
      const onArchive = () => user.archive().then(this.onArchived);

      this.$modal.show(ModalArchive,
        {
          item: user,
          onArchive,
          text: `${this.$t('confirmation.confirm_archive_user')} <strong>${user.name}</strong>?`
        }
      );
    },

    unArchive(user) {
      return user.unArchive().then(this.onUnArchived);
    },

    onArchived() {
      this.$notice.show('notices.user_archived');
      this.loadUsers(true);
    },

    onUnArchived() {
      this.$notice.show('notices.user_restored');
      this.loadUsers(true);
    },

    onSaved(isEdit) {
      this.$notice.show('notices.user_saved');

      //Reload users if we added a new one
      if (!isEdit) {
        this.loadUsers(true);
      }
    },

    loadPage(page) {
      this.page = page;
      this.loadUsers(true);
    },

    async loadUsers() {
      this.isLoading = true;

      const filter = this.makeFilter();

      await UserApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false);
    },

    makeFilter(extra) {
      //Initialize filter
      const filter = this.filter.toJSON();
      const { page, itemsPerPage } = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra);
      }

      return filter;
    },

    processData(data) {
      const { meta, users } = data;

      //Set properties and flags
      this.users = users;
      this.totalItems = meta.total;
    },
  },
};
</script>

<template>
  <div class="Page">
    <page-meta 
      type="users.user"
      
      :filter="filter"
      :total-items="totalItems"
      :num-pages="numPages"
      :page="page"
      :load-page="loadPage"
      :showAddButton="false"
      >
      <template slot="settings">
        <setting-options>
          <li>
            <a @click="$router.push('/workspace/assets/assetTypes')">
              <i class="Icon-Base">widgets</i>{{$t('shared_settings-options.asset_types')}}
            </a>
          </li>
          <li>
            <a @click="$router.push({ name: 'integrations' })">
              <i class="Icon-Base">device_hub</i>{{$t('shared_settings-options.integrations')}}
            </a>
          </li>
          <hr />
          <li>
            <a :href="$t('shared_settings-options.help_users_link')" target="_blank">
              <i class="Icon-Base">help</i>{{$t('shared_settings-options.help_users')}} <i class="Icon-External">launch</i>
            </a>
          </li>
        </setting-options>
      </template>
      <template slot="tabs">
        <div class="PageMeta-tab--first" :class="{ 'is-active': (filter.isArchived === false) }"
          @click="filter.resetToDefaults()">
          {{ $t('users.active_users') }}
        </div>
        <div class="PageMeta-tab" :class="{ 'is-active': (filter.isArchived === true) }"
          @click="filter.update('isArchived', true)">
          {{ $t('users.archived_users') }}
        </div>
      </template>

      <template slot="actions">
        <button :disabled="allUsersLimitReached" @click="invite" class="Button--cta">
          {{ $t('users.add_user') }}
        </button>
      </template>

      <template slot="warning" v-if="allUsersLimitReached">
        <div class="Message-Title">
          {{ $t('users.user_limit_reached') }}.
          <router-link to="/account/billing">
            {{ $t('users.upgrade_to_add') }}
          </router-link>
        </div>
      </template>

    </page-meta>

    <div class="PageContents">
      <div class="Container">
        <div class="Table">
          <div class="Table-header">
            <div class="Table-col--L">
              <sort :sort="filter.sort" field="name" @toggle="filter.update('sort', $event.sort)">
                {{ $t('users.user_name') }}</sort>
            </div>
            <div class="Table-col--XL Hide-BreakPointMedium">
              <sort :sort="filter.sort" field="email" @toggle="filter.update('sort', $event.sort)">
                {{ $t('users.user_email') }}</sort>
            </div>
            <div class="Table-col--M">
              <sort :sort="filter.sort" field="role" @toggle="filter.update('sort', $event.sort)">
                {{ $t('users.user_role') }}</sort>
            </div>
            <div class="Table-col--options" />
          </div>

          <div v-if="isLoading && !hasItems">
            <spinner class="Spinner--general" />
          </div>

          <div v-if="!isLoading && !hasItems" class="Table-noResults">
            {{ $t('global.table_no_results') }}
          </div>

          <div v-if="hasItems">
            <div v-for="user in users" :key="user.id" class="Table-row">
              <div class="Table-col--L Table-col--primary Item-Headline" @click="edit(user)">
                <div class="ItemM">
                  <div class="Item-Icon">
                    <div class="Table-Avatar" v-if="user.name">
                      <div class="UserAvatar--table">{{user ? user.initials : ''}}</div>
                    </div>
                    <div v-else class="UserAvatar--tablePending">–</div>
                  </div>
                  <span class="Item-TextWrap">
                    <span v-if="user.name">{{user.name}}</span>
                    <span v-else class="User-Pending">...</span>
                  </span>
                </div>
              </div>
              <div class="Table-col--XL Table-col--userEmail Hide-BreakPointMedium">
                <a :href="'mailto:' + user.email" target="_blank">{{ user.email }}</a>
                <span v-if="user.inviteAccepted == false"
                  class="Pill--warning User--invited">{{ $t('users.user_invited') }}</span>
              </div>
              <div class="Table-col--M">{{ user.role }} <span v-if="!user.name"
                  class="User-Description">{{ $t('users.user_acceptance_pending') }}</span></div>
              <div class="Table-col--options">
                <setting-options v-if="user.archived" :options="['unArchive']" @un-archive="unArchive(user)" />
                <setting-options v-else-if="user.inviteAccepted == false" :options="['edit', 'remove']" @edit="edit(user)"
                  @remove="remove(user)" />
                <setting-options v-else-if="user.role == 'User' || !justOneAdmin" :options="['edit', 'archive']"
                  @edit="edit(user)" @archive="archive(user)" />
                <setting-options v-else :options="['edit']" @edit="edit(user)" />
              </div>
            </div>
          </div>
        </div>
        <pagination :show-all="totalItems < 500" :page="page" class="Pagination--lower" :num-pages="numPages"
          @change="loadPage($event.page)" />
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
