<script>
import AssetTypesApi from '@/api/asset-type.api';
import AssetType from '@/models/asset-type.model';
import EditModal from './edit';
import AddTypeOptionsModal from './add-type-options';
import ModalRemove from '@/components/shared/modal-remove';

export default {

  data() {
    return {
      allItems: [],
      isLoading: false,
      assetTypes: [],
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,
      filter: 'all',
    };
  },

  props: {
    typeId: { type: String },
    addNewNow: { type: Boolean },
  },

  computed: {
    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },

  async created() {
    await this.loadItems();
    if (this.typeId) {
      const itemType = await AssetTypesApi.findById(this.typeId);
      if (itemType) {
        this.edit(itemType);
      }
    }

    if (this.addNewNow) {
      this.AddNew();
    }
  },

  methods: {
    async loadItems() {
      this.isLoading = true;

      await AssetTypesApi.query()
        .then(res => {
          this.allItems = res.items;
          this.totalItems = res.meta.total;
          this.filterBy(this.filter);
          this.isLoading = false;
        })
    },

    AddNew() {
      this.$modal.show(
        AddTypeOptionsModal,
        {
          addType: async () => {
            const item = new AssetType();
            item.icon = 'help_outline';
            this.edit(item, false);
          },
        },
        { maxWidth: 800 },
      );
    },

    edit(item, isEdit = true) {
      this.$modal.show(
        EditModal,
        {
          value: new AssetType(item),
          onSave: this.onSaveType,
          isEdit,
        },
        {},
      );
    },

    onSaveType(type) {
      return (type.id
        ? AssetTypesApi.update(type.id, type)
        : AssetTypesApi.create(type)
      )
        .then(() => {
          this.$notice.show('notices.item_saved');
          const index = this.assetTypes.findIndex(i => i.id === type.id);
          if (index >= 0) {
            Object.assign(this.assetTypes[index], type);
          }
          this.loadItems();
        });
    },

    remove(assetType) {
      const onRemove = () => {
        return AssetTypesApi
          .remove(assetType.id)
          .then(() => {
            this.$notice.show('notices.item_removed');
            this.loadItems();
          });
      };

      this.$modal.show(ModalRemove, {
        singular: 'asset type',
        onRemove,
        text: `${this.$t('confirmation.confirm_delete_asset_type')} <strong>${assetType.name}</strong>?`,
      });
    },

    filterBy(value) {
      this.filter = value;
      if (value === 'all') {
        return this.assetTypes = [].concat(this.allItems);
      }
      if (value === 'integration') {
        return this.assetTypes = this.allItems.filter(item => item.integrationId);
      }
      return this.assetTypes = this.allItems.filter(item => !item.integrationId);
    },
  },
};
</script>

<template>
  <div class="Modal Modal--assetTypeManager">
    <div class="Modal-Header">
      <div class="Modal-Title--wrap">
        <h1>{{ $t('asset-types_view.title') }}</h1> 
        <span class="Asset-Type-Number">— <b>{{ totalItems }}</b> {{ $t('asset-types_view.currently_defined') }}</span>
      </div>
      <div @click="$emit('close')">
        <i class="Icon-ModalsClose">close</i>
      </div>
    </div>
      <div v-if="isLoading" class="ModalBody">
        {{ $t('global.loading') }} ...
      </div>
      <div class="ModalBody" v-else>
        <div class="Modal-tabs">
          <div class="Modal-tab Modal-tab-NoLeftMargin" :class="{ 'is-active': filter === 'all' }">
            <span @click="filterBy('all')">{{ $t('asset-types_view.all_types') }}</span>
          </div>
          <div class="Modal-tab" :class="{ 'is-active': filter === 'integration' }">
            <span @click="filterBy('integration')">{{ $t('asset-types_view.apps_defined') }}</span>
          </div>
          <div class="Modal-tab" :class="{ 'is-active': filter === 'custom' }">
            <span @click="filterBy('custom')">{{ $t('asset-types_view.defined_internally') }}</span>
          </div>
          <button @click="AddNew()" class="Button--primary">{{ $t('asset-types_view.add_type') }}</button>
        </div>

        <div class="AssetTypesBg">
          <div v-if="!isLoading">

            <div v-if="assetTypes.length === 0" class="Asset-Type-noResults">
                {{ $t('asset-types_view.no_types_defined') }}
            </div>
            <div v-else class="Blocks-Wrap--assetTypes">
              <div v-for="assetType in assetTypes" :key="assetType.id" class="Block--assetType">
                <div class="Block-Header">
                  <div class="ItemM">
                    <icon :name="assetType.icon" class="Item-Icon" />
                    <div class="Item-TextWrap">
                      <div class="Item-Headline">
                        {{ assetType.name }}
                      </div>
                      <div class="Description">
                        {{ assetType.integrationId ? 'Integration' : $t('asset-types_view.internal') }}
                      </div>
                    </div>
                  </div>
                  <setting-options
                    v-if="assetType.integrationId || assetType.assetCount > 0"
                    :options="['edit']"
                    @edit="edit(assetType)"
                  />
                  <setting-options
                    v-else
                    :options="['edit', 'remove']"
                    @edit="edit(assetType)"
                    @remove="remove(assetType)"
                  />
                </div>
                <hr />
                <div class="Asset-Type--meta">
                  <div class="Tally-Stats">
                    <div v-if="assetType.integrationId && assetType.assetCount > 0" class="Tally-Stat--exists">
                      <span class="Tally-Number">{{assetType.assetCount}}</span> {{ $t('asset-types_view.items_integration') }}
                    </div>
                    <div v-if="assetType.integrationId && assetType.assetCount === 0" class="Tally-Stat--nil">
                      <span class="Tally-Number">{{assetType.assetCount}}</span> {{ $t('asset-types_view.items_integration') }}
                    </div>
                    <div v-if="!assetType.integrationId && assetType.assetCount > 0"  class="Tally-Stat--exists">
                      <span class="Tally-Number">{{assetType.assetCount}}</span> {{ $t('asset-types_view.items') }}
                    </div>
                    <div v-if="!assetType.integrationId && assetType.assetCount === 0"  class="Tally-Stat--nil">
                      <span class="Tally-Number">{{assetType.assetCount}}</span> {{ $t('asset-types_view.items') }}
                    </div>
                  </div>

                  <div v-if="assetType.notes"  class="TooltipUnder--assetType">
                    <i class="Icon-Base">sticky_note_2</i>
                    <div class="Tooltip-text">{{assetType.notes}}</div>
                  </div>
                </div>
  
              </div>
            </div>
          </div>

          <pagination
            :show-all="totalItems < 500"
            :page="page"
            :num-pages="numPages"
            @change="loadPage($event.page)"
          />
        </div>
      </div>
  </div>
</template>

<style scoped lang="scss">

.ModalMeta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 -38px;
}
.Modal-Title--wrap {
  display: flex;
  align-items: center;
  gap: $gapM;
  .Asset-Type-Number {
    padding-top: 5px;
  }
}
.Asset-Type--meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: $gapM;
  padding: 0 $spacingM;
}

.AssetTypesBg {
  background: $colorBgPanels;
  margin: 0 -$spacingL;
  margin-top: $spacingM;
  padding: $spacingXL;
  min-height: 400px;
}

.Asset-Type-noResults {
  color: $colorTextFaded1;
  font-weight: $fontWeightNormal;
  font-size: $fontSizeXL;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -2rem;
}
</style>
