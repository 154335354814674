<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/shared/modal-edit';
import Contact from '@/models/contact.model';
import ContactApi from '@/api/contact.api';
import ModelSearch from '@/components/shared/model-search';

export default {
  name: 'AssetEdit',

  components: { ModalEdit, ModelSearch },

  data() {
    return {
      ContactApi,
      model: {
        contact: null,
        item: null,
        assetType: {
          integration: null,
        },
        name: null,
        description: null,
      },
      original: {},
    };
  },

  props: {
    item: { type: Object },
    contacts: { type: Array },
    onSave: { type: Function },
    onArchive: { type: Function },
    isEdit: { type: Boolean },
    showTypes: { type: Function },
    types: { type: Array },
    editOwner: { type: Boolean },
  },

  validations: {
    model: {
      assetType: { required },
      name: { required },
    },
  },

  computed: {
    isDisabledConfirm() {
      return _.isEqual(this.model, this.original);
    },

    nonIntegrationTypes() {
      return this.types.filter(type => !type.integration);
    },
  },

  created() {
    //Create model subset
    this.model = this.item.extract(Object.keys(this.model));
    this.model.assetType = this.model.assetType || {};
    this.original = _.cloneDeep(this.model);
  },

  mounted() {
    if (this.editOwner) {
      global.document.getElementById(this.$htmlID('contact')).focus()
    }
  },

  methods: {
    selectContact($event) {
      if ($event.option && $event.option.createNew) {
        const newContact = new Contact();
        newContact.name = $event.value;
        newContact.type = 'person';

        return this.model.contact = newContact;
      }
      this.model.contact = $event.value;
    },

    selectType($event) {
      const selectedId = $event.id;
      if (selectedId) {
        const selectedType = this.types.filter(item => item.id === selectedId)[0];
        this.model.assetType = {
          id: selectedId,
          name: selectedType.name,
          icon: selectedType.icon,
        };
      }
    },
  },
};
</script>

<template>
  <modal-edit
    :singular="model.assetType?.name ? model.assetType.name : 'item'"
    :model="model"
    :validation="$v"
    :is-edit="isEdit"
    :on-save="onSave"
    :on-archive="onArchive"
    :is-disabled-confirm="isDisabledConfirm"
    @close="$emit('close')"
  >
    <div v-if="!isEdit" class="Message-Info">
      <div class="Message-Header">
        <i class="Icon-Base--info">info</i>
        <div class="Message-Header--textWrap">
          <span>{{ $t('assets_modals_edit.type_restrictions') }}</span>
        </div>
      </div>
    </div>
    <div class="SpacerL"></div>

    <div class="Form">
      <div v-if="isEdit || nonIntegrationTypes.length">
        <div class="Group">
          <label :for="$htmlID('contact')" class="Label">{{ $t('assets_modals_edit.owner') }}</label>
          <model-search
            ref="contact"
            :id="$htmlID('contact')"
            :model="model.contact"
            :placeholder="$t('contracts_modals_edit.client_lookup')"
            :api="ContactApi"
            @change="selectContact($event)"
           />
        </div>
        <form-group>
          <div class="Group" :class="{ 'Group--error': $v.model.assetType?.$error }">
            <label :for="$htmlID('type')" class="Label">{{ $t('assets_modals_edit.type') }}</label>

            <div v-if="model.assetType?.integration" class="Input-100">
              <div class="disabled">
                {{ model.assetType?.name }}
              </div>
            </div>

            <select-box v-else :options="nonIntegrationTypes" :as-object="true" track-by="id" label-by="name"
              null-label="Select type of asset..." :model="model.assetType.id" @change="selectType($event.value)" />

            <div class="InputHint" v-if="$v.model.assetType.$error">
              <div v-if="!$v.model.assetType.required">{{ $t('assets_modals_edit.type_is_required') }}</div>
            </div>
          </div>
        </form-group>

        <div class="Group" :class="{ 'Group--error': $v.model.name.$error }">
          <label :for="$htmlID('name')" class="Label">{{ $t('assets_modals_edit.asset_name') }}</label>
          <input :disabled="model.assetType.integration" :id="$htmlID('name')" class="Input-100" type="text"
            :title="$t('assets_modals_edit.set_at_origin')" v-model="model.name">
          <div class="InputHint" v-if="$v.model.name.$error">
            <div v-if="!$v.model.name.required">{{ $t('assets_modals_edit.name_is_required') }}</div>
          </div>
        </div>
        <div class="Group">
          <label :for="$htmlID('description')" class="Label">{{ $t('assets_modals_edit.description') }}</label>
          <textarea :id="$htmlID('description')" class="Input-100" v-model="model.description" />
        </div>
      </div>
    </div>
  </modal-edit>
</template>

<style lang="scss" scoped>
.Button-Wrap--centered {
  display: flex;
  justify-content: center;
}
</style>
```
