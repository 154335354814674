<script>
import ModalView from '@/components/shared/modal-view';

export default {
  components: { ModalView },

  props: {
    item: { type: Object },
    onEdit: { type: Function },
  },
  computed: {
    filteredInvoicePrices() {
      return Object.entries(this.item.invoicePrices)
        .filter(([key, value]) => value !== null) // Exclude null values
        .map(([key, value]) => ({
          period: key,
          price: value
        }));
    }
  }
};
</script>

<template>
  <modal-view :singular="item.name" :byline="$t('offerings_modals_view.byline')" icon="local_offer" :item="item"
    :on-edit="item.archived ? null : onEdit" :saveable="false" @close="$emit('close')">
    <div class="Section Modal-Panel">
        <div class="Row-0">
          <div class="Modal-col--M">
            <label class="Label">{{ $t('offerings_modals_view.invoice_price') }}</label>
            <div class="Prices-Wrap">
              <div v-for="priceInfo in filteredInvoicePrices" :key="priceInfo.period" class="ItemL Offering-price--item">
                <div class="Price-Wrap">
                  <icons-services :period="priceInfo.period" />
                  <div class="PeriodPrice">
                    <div class="Item-TextWrap">{{ priceInfo.price | currency }}</div>
                    <div class="offering-period">
                      {{ $t(`global.periods.${priceInfo.period}`) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Modal--item">
              <label class="Label">{{ $t('offerings_modals_view.description') }}</label>
              <div v-if="item.description" class="Modal-Value-description">{{ item.description }}</div>
              <div v-else class="Modal-Value-description Modal-Value--notSet">
                {{ $t('offerings_modals_view.no_invoice_description') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    <div class="SpacerL" />

    <div class="Section">
      <div class="Modal--item">
        <label class="Label">{{ $t('offerings_modals_view.asset_types') }}</label>

        <div v-if="item.assetTypes?.length > 0">
          <div class="Blocks-Wrap--assetTypes">
            <div v-for="assetType in item.assetTypes" :key="assetType.id" class="Block--assetType">
              <div class="ItemM">
                <div class="Item-Icon">
                  <icon :name="assetType.icon" />
                </div>
                <div class="Item-TextWrap">
                  <span class="Item-Headline">{{ assetType.name }}</span>
                  <div class="Description">
                    {{ assetType.integration ? 'Integration' : $t('asset-types_view.internal') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          {{ $t('offerings_modals_view.asset_types_not_associated') }}
        </div>
      </div>

      <!-- <modal-link /***** Commented out and left in case there's a need for this later  *****/
        class="Button--cta Button--small"
        :to="`/workspace/services/add?offeringId=${item.id}`"
        :from="`/workspace/offering/view/${item.id}`"
        fromName="offering "
      >
        {{$t('offerings_modals_view.add_contract')}}
      </modal-link> -->
    </div>
  </modal-view>
</template>

<style lang="scss" scoped>

.Offering-price--item {
  font-weight: $fontWeightMedium;
}
.Prices-Wrap {
  display: flex;
  justify-content: flex-start;
  gap: $spacing3XL;
  padding: $spacingS 0 $spacing2XL;
}

.Price-Wrap {
  display: flex;
  align-items: center;
  gap: $gapM;
  margin: 0;
  font-size: $fontSizeM;
}

.offering-period {
  color: $colorTextFaded1;
  font-weight: $fontWeightNormal;
  font-size: $fontSizeS;
  text-transform: lowercase;
  text-align: center;
}
</style>
