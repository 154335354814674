<script>
import { mapState } from 'vuex';
import AuditLogApi from '@/api/audit-log.api';

export default {
  props: {
    limit: {
      type: Number,
      default: 10,
    },
    item: { type: Object },
    show: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      history: [],
      isVisible: this.show,
      isLoading: true,
    };
  },

  created() {
    if (this.item) {
      this.isVisible = false;
      this
        .loadHistory()
        .then(() => {
          this.isVisible = this.$props.show;
          this.isLoading = false;
        });
    }
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
    }),

    timezone() {
      return this.account?.timezone;
    },
  },

  methods: {
    async loadHistory() {
      this.history = await AuditLogApi
        .query({
          id: this.item.id,
          model: this.item.getModelName(),
          limit: this.limit,
        })
        .then(data => data.logs);
    },

    formatValue(name, value) {
      if (name.toLowerCase().includes('price')) {
        return this.$format('currency', value);
      }
      else {
        return value;
      }
    }
  },
};
</script>

<template>
  <div class="ItemHistory">
    <div class="ItemHistory_wrapper">
      <div v-if="isLoading">
        <spinner class="Spinner-Sidepanel--history" />
      </div>

      <div class="HistoryItems">
        <div v-for="line in history" :key="line.id">
          <span v-html="line.oneLinerItemHistory(timezone)" />

          <div class="Change-Wrap" v-if="line.changed">
            <div v-for="(item, name) in line.changed" :key="name">
              <div v-if="item.old && item._new">
                <div class="Change-To">
                  <span class="Thing-Changed">{{ name }}</span> {{$t('item_history.changed')}} {{$t('item_history.to')}}:
                </div>
                <span class="New-Value">{{ formatValue(name, item._new) }}</span>
                <div class="Change-From">
                  {{$t('item_history.from')}}:
                </div>
                <span class="Old-Value">{{ formatValue(name, item.old) }}</span>
              </div>
              <div v-else-if="item._new">
                <span class="Set-Value"> <span class="Thing-Changed">{{ name }}</span> {{$t('item_history.set_to')}}</span>:
                <div class="New-Value">{{ formatValue(name, item._new) }}</div>
              </div>
              <div v-else-if="item.old">
                <div class="Remove-Value">
                  <span class="Thing-Changed">{{ name }}</span> {{$t('item_history.value_removed')}}:
                </div>
                <div class="Old-Value">{{ formatValue(name, item.old) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
