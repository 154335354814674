<script>
export default {
  props: {
    addType: { type: Function },
  },

  methods: {
    _addType() {
      this
        .addType()
        .then(() => this.$emit('close'));
    },
  },
};
</script>

<template>
  <div class="Modal">
    <div class="Modal-Header">
      <h1>{{$t('asset-types_modals_add-type-options.asset_types_options')}}</h1>
      <div @click="$emit('close')">
        <i class="Icon-ModalsClose">close</i>
      </div>
    </div>
    <div class="Modal-wrap">
      <div>
        <div class="Message-Warning">
          <div class="Message-Header">
            <i class="Icon-Base--warning">error_outline</i>
            <div class="Message-Title">{{ $t('asset-types_modals_add-type-options.no_type_message') }}
            </div>
          </div>
        </div>
        <div class="SpacerL"></div>
        <div class="Asset-OptionsWrap">
          <div class="Asset-Option">
            <h2 class="asset_type_title">{{$t('asset-types_modals_add-type-options.integration_type_title')}}</h2>
            <div class="Description">{{$t('asset-types_modals_add-type-options.recommended')}}</div>
            <div class="SpacerS"></div>
            <div class="SpacerL"></div>
            <modal-link to="/integrations/search" class="Button--primary Button-100">
              {{$t('asset-types_modals_add-type-options.view_integrations')}}
            </modal-link>
            <div class="SpacerS"></div>
            <div class="Description">{{$t('asset-types_modals_add-type-options.connect_external_asset_notes')}}</div>
          </div>
          <hr />
          <div class="Asset-Option">
            <div>
              <h2 class="asset_type_title">{{$t('asset-types_modals_add-type-options.manual_type_title')}}</h2>
              <div class="SpacerXL"></div>
              <div class="SpacerL"></div>
            </div>
            <button @click="_addType"
              class="Button--plain Button-100">{{$t('asset-types_modals_add-type-options.add_type_manually')}}
            </button>
            <div class="SpacerS"></div>
            <div class="Description">{{$t('asset-types_modals_add-type-options.define_custom_asset_notes')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.Asset-OptionsWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 $spacingL;

  h2.asset_type_title {
    padding-bottom: 0;
  }

  .Asset-Option {
    width: 45%;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .Asset-Option-Content {
    margin-bottom: $spacingXL;
    font-size: $fontSizeM;
  }
}
</style>
