<script>

import AssetApi from '@/api/asset.api';

export default {
  data() {
    return {
      stats: {
        assets: {
          by_type: [],
        },
      },
      isLoading: false,
    };
  },

  async created() {
    this.isLoading = true;
    this.stats.assets = await AssetApi.summary();
    this.isLoading = false;
  },

  computed: {
    isEmpty() {
      return this.stats.assets.by_type?.length === 0;
    },
  },
};
</script>

<template>
  <div class="PanelSection">
    <div class="Dashlist-Panel--info">
      <div class="PanelHeader">
        <div class="PanelHeader-title">
          {{$t('dashboard_dashboard-items_dashlist-assets.asset_list')}}
        </div>
        <div class="PanelHeader-options">
        </div>
      </div>
      <spinner v-if="isLoading" class="Dashlist-Spinner" />

      <div v-if="!isLoading"  class="DashList">
        <div class="Dashlist-Empty" v-if="(isEmpty&&!isLoading)">{{$t('dashboard_dashboard-items_dashlist-assets.no_assets')}}</div>
        <div v-for="(item, index) in stats.assets.by_type" v-bind:key="index">
          <router-link class="Dashlist-Item" :to="{
            path: '/workspace/assets',
            query: { type: item.name }
          }">
              <i class="Icon-DashList">
                <icon :name="item.icon || 'widgets'" />
              </i>

            <span class="Dashlist-Item--name">{{item.name | inflect('pluralize')}}</span>
            <span class="Dashlist-Item--value">{{item.total}}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>