<script>
import { mapState } from 'vuex';
import OfferingApi from '@/api/offering.api';
import AssetTypesApi from '@/api/asset-type.api';
import Offering from '@/models/offering.model';
import OfferingEdit from './modals/edit';
import OfferingView from './modals/view';
import ModalArchive from '@/components/shared/modal-archive';
import OverviewOnboarding from '@/components/shared/overview-onboarding.vue';
import IconsOfferings from './icons-offerings.vue';

export default {
  components: { OverviewOnboarding, IconsOfferings },

  data() {
    return {
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,
      offerings: [],
      assetTypes: [],
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      user: state => state.session.user,
    }),

    hasItems() {
      return this.offerings.length > 0;
    },

    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },

  created() {
    this.setupFilter();
    this.setupPage();
    this.loadOfferings();
    this.loadAssetTypes();
    this.processAction();
  },

  methods: {
    setupFilter() {
      this.filter = this.$filter.get('offerings');
      this.filter.setDefaults({
        search: '',
        sort: 'name',
        from: null,
        to: null,
        by: '',
        stage: null,
        isArchived: false,
      });

      //Load initial values from query params
      this.filter.loadValues(this.$route.query);

      //On change handler
      this.filter.onChange(() => {
        this.loadPage(1);
      });
    },

    setupPage() {
      const { filter } = this;
      this.$store.dispatch('page/setup', { filter });
    },

    async processAction() {
      const { action, id } = this.$route.params;

      if (action === 'add') {
        return this.add();
      }

      if (action === 'view' && id) {
        const offering = await OfferingApi.findById(id);
        if (offering) {
          this.view(offering);
        }
      }

      if (action === 'edit' && id) {
        const offering = await OfferingApi.findById(id);
        if (offering) {
          this.edit(offering);
        }
      }
    },

    add() {
      const offering = new Offering();
      this.edit(offering, false);
    },

    view(offering) {
      console.log('view', offering);

      const onEdit = async () => this.edit(offering);
      const onArchive = () => offering.archive().then(this.onArchived);

      this.$modal.show(
        OfferingView, 
        { item: offering, onEdit, onArchive },
        { maxWidth: 1000 },
        {
          opened: () => this.$router.push({ name: 'offerings', params: { action: 'view', id: offering.id }}),
          closed: () => this.$router.replace({ name: 'offerings' }),
        }
      );
    },

    edit(offering, isEdit = true) {
      const { assetTypes } = this;

      const onSave = async model => {
        model.assetTypeIds = model.assetTypes.map(({ id }) => id);
        await offering.save(model);
        this.onSaved(offering);
      }

      this.$modal.show(
        OfferingEdit, 
        { item: offering, isEdit, onSave, assetTypes },
        { maxWidth: 950 },
        {
          opened: () => this.$router.push({ name: 'offerings', params: { action: isEdit ? 'edit' : 'add', id: offering.id }}),
          closed: () => this.$router.replace({ name: 'offerings' }),
        }
      );
    },

    onSaved(offering) {
      this.$notice.show('notices.offering_saved');
      if (!this.user.experiences.offering_created) {
        this.user.experiences.offering_created = true;
        this.user.updateOwn(this.user);
      }
      this.view(offering);
      this.loadOfferings();
    },

    archive(offering) {
      const onArchive = () => offering.archive().then(this.onArchived);

      this.$modal.show(ModalArchive,
        {
          item: offering,
          onArchive,
          text: `${this.$t('confirmation.confirm_archive_offering')} <strong>${offering.name}</strong>?`
        }
      );
    },

    onArchived() {
      this.$notice.show('notices.offering_ archived');
      this.loadOfferings();
    },

    unArchive(offering) {
      return offering.unArchive().then(this.onUnArchived);
    },

    onUnArchived() {
      this.$notice.show('notices.offering_restored');
      this.loadOfferings(true);
    },

    /**
     * Load page of items
     */
    loadPage(page) {
      this.page = page;
      this.loadOfferings();
    },

    async loadOfferings() {
      this.isLoading = true;

      //Get filter and query data
      const filter = this.makeFilter();

      await OfferingApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false);
    },

    async loadAssetTypes() {
      await AssetTypesApi.query()
        .then(({ items }) => this.assetTypes = items);
    },

    makeFilter(extra) {

      //Initialize filter
      const filter = this.filter.toJSON();
      const { page, itemsPerPage } = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra);
      }

      return filter;
    },

    processData(data) {
      const { meta, offerings } = data;

      //Set properties and flags
      this.offerings = offerings;
      this.totalItems = meta.total;
    },
  },
};
</script>

<template>
  <div class="Page">
    <page-meta
      type="offerings_overview.title"
      
      :filter="filter"
      :total-items="totalItems"
      @add="add"
      :num-pages="numPages"
      :page="page"
      :load-page="loadPage"
      >
      <template slot="settings">
        <setting-options>
          <li>
            <router-link to="/workspace/assets/assetTypes"><i class="Icon-Base">widgets</i>{{$t('shared_settings-options.asset_types')}}</router-link>
          </li>
          <li>
            <router-link to="/integrations"><i class="Icon-Base">device_hub</i>{{$t('shared_settings-options.integrations')}}</router-link>
          </li>
          <hr />
          <li>
            <a :href="$t('shared_settings-options.help_offerings_link')" target="_blank">
              <i class="Icon-Base">help</i>{{$t('shared_settings-options.help_offerings')}} <i class="Icon-External">launch</i>
            </a>
          </li>
        </setting-options>
      </template>
      <template slot="tabs">
        <div class="PageMeta-tab--first" :class="{ 'is-active': filter.isArchived === false }">
          <span @click="filter.resetToDefaults()">{{ $t('offerings_overview.filter_button_all') }}</span>
        </div>
        <div class="PageMeta-tab" :class="{ 'is-active': filter.isArchived === true }">
          <span @click="filter.update('isArchived', true);">{{ $t('offerings_overview.filter_button_archived') }}</span>
        </div>
      </template>
    </page-meta>

    <div class="PageContents">
      <div class="Container">

        <div class="Table">
          <overview-onboarding experience="offerings_overview_onboarding">
            <div class="Section-Onboarding--Img">
              <div class="Spacer2XL"></div>
              <i class="Icon-Base">sell</i>
            </div>
            <div class="Section-Onboarding--Content">
              <h2 class="OnboardingTitle">{{ $t('onboarding_offerings.offerings_title') }}</h2>
              <div class="OnboardingIntro">
                {{ $t('onboarding_offerings.offerings_intro') }}
              </div>

              <p>{{ $t('onboarding_offerings.offerings_instruction_line') }}</p>

              <i18n path="onboarding_offerings.read_more.text" tag="p" class="Section-Onboarding--Guidelink">
                <template v-slot:url>
                  <a :href="$t('onboarding_offerings.read_more.link')" target="_blank"
                    :title="$t('onboarding_offerings.read_more.title')">
                    {{ $t('onboarding_offerings.read_more.urltext') }}</a><i class="Icon-External">launch</i>
                </template>
              </i18n>
            </div>
          </overview-onboarding>
          <div class="Table-header">
            <div class="Table-col--L">
              <sort :sort="filter.sort" field="name" @toggle="filter.update('sort', $event.sort)">
                {{ $t('offerings_overview.offering_name') }}</sort>
            </div>
            <div class="Table-col--S Hide-BreakPointSmall">
              {{ $t('offerings_overview.list_prices') }}
            </div>
            <div class="Table-col--S Hide-BreakPointMedium">
              {{ $t('offerings_overview.offering_assets') }}
            </div>
            <div class="Table-header--spacer" />
          </div>

          <div v-if="isLoading && !hasItems">
            <spinner class="Spinner--general" />
          </div>
          <div v-if="!isLoading && !hasItems" class="Table-noResults">
            {{ $t('global.table_no_results') }}
          </div>

          <div v-if="hasItems">
            <div v-for="offering in offerings" :key="offering.id" class="Table-row">
              <div class="Table-col--L Table-col--primary" @click="view(offering)">
                <div>
                  <div class="ItemM">
                    <div class="Item-Icon">
                      <i class="Icon-Base">local_offer</i>
                    </div>
                    <div class="Item-TextWrap">
                      <div class="Item-Headline">
                        {{ offering.name }}
                      </div>
                      <div class="Description">
                        Service Offering
                      </div>
                    </div>
                  </div>
                <div>
                  <div class="SpacerS"></div>
                  <div v-if="(offering.description)" class="Table-Teaser Hide-BreakPointMedium">{{ offering.description }}</div>
                  <div v-else class="Table-Value--notSet">{{ $t('offerings_overview.no_invoice_description') }}</div>
                </div>
              </div>
              </div>
              <div class="Table-col--S Table-col--DefaultPrices Hide-BreakPointSmall">
                <template>
                  <IconsOfferings :invoice-prices="offering.invoicePrices" />
                </template>
              </div>

              <div class="Table-col--S Table-col--assetTypes Hide-BreakPointMedium">
                  <div v-for="assetType in [...offering.assetTypes].sort((a, b) => a.name.localeCompare(b.name))" :key="assetType.id" class="ItemS">
                    <div class="Item-Icon">
                      <icon :name="assetType.icon" />
                    </div>
                    <div class="Item-TextWrap">
                      {{assetType.name}}
                    </div>
                  </div>
              </div>
              <div class="Table-col--options">
                <setting-options
                  v-if="offering.archived"
                  :options="['view', 'unArchive']"
                  @view="view(offering)"
                  @un-archive="unArchive(offering)"
                />
                <setting-options
                  v-else-if="offering.contractCount == 0"
                  :options="['view', 'edit', 'archive']"
                  @view="view(offering)"
                  @edit="edit(offering)"
                  @archive="archive(offering)"
                />
                <setting-options
                  v-else
                  :options="['view', 'edit', 'archive-disabled']"
                  @view="view(offering)"
                  @edit="edit(offering)"
                />
              </div>
            </div>
          </div>
        </div>
        <pagination
          :show-all="totalItems < 500"
          :page="page"
          class="Pagination--lower"
          :num-pages="numPages"
          @change="loadPage($event.page)"
        />
      </div>
    </div>
  </div>
</template>