<script>
import { mapState } from 'vuex';
import CreditCardApi from '@/api/credit-card.api';
import IntegrationApi from '@/api/integration.api';
import CreditCard from '@/models/credit-card.model';
import ModalRemove from '@/components/shared/modal-remove';
import OverviewOnboarding from '@/components/shared/overview-onboarding.vue';
import CreditCardEdit from './modals/edit';

export default {
  name: 'CreditCardOverview',

  components: { OverviewOnboarding },

  data() {
    return {
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,
      creditCards: [],
      filter: null,
      isLoading: false,
      stripeIntegration: null,
    };
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
    }),

    hasItems() {
      return this.creditCards.length > 0;
    },

    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },

    stripeConnected() {
      return this.stripeIntegration?.isConnected;
    },
  },

  async created() {
    this.setupFilter();
    this.setupPage();
    this.loadCreditCards();
    this.processAction();
    this.stripeIntegration = await IntegrationApi.findByType('stripe');
  },

  methods: {
    setupFilter() {
      this.filter = this.$filter.get('creditCards');
      this.filter.setDefaults({
        search: '',
        // sort: 'number',
        from: null,
        to: null,
        status: null,
      });

      //Load initial values from query params
      this.filter.loadValues(this.$route.query);

      //On change handler
      this.filter.onChange(() => {
        this.loadPage(1);
      });
    },

    setupPage() {
      const { filter } = this;
      this.$store.dispatch('page/setup', { filter });
    },

    async processAction() {
      const { action, id } = this.$route.params;

      if (action === 'add') {
        return this.add();
      }

      if (action === 'edit' && id) {
        const creditCard = await CreditCardApi.findById(id);
        if (creditCard) {
          this.edit(creditCard);
        }
      }
    },

    add() {
      const creditCard = new CreditCard();
      this.edit(creditCard, false);
    },

    edit(creditCard, isEdit = true) {
      const { account } = this;

      const onSave = (model) => {
        return creditCard
          .save(model)
          .then(() => this.onSaved(isEdit));
      };

      const onRemove = () => {
        return creditCard
          .remove()
          .then(() => this.onRemoved(creditCard));
      };

      this.$modal.show(
        CreditCardEdit, 
        { account, item: creditCard, isEdit, onSave, onRemove },
        {},
        {
          opened: () => this.$router.push({ name: 'credit cards', params: { action: 'edit', id: creditCard.id } }),
          closed: () => this.$router.replace({ name: 'credit cards' }),
        }
      );
    },

    remove(creditCard) {
      const onRemove = () => {
        return creditCard
          .remove()
          .then(() => this.onRemoved(creditCard));
      };

      //Open confirmation modal
      this.$modal.show(ModalRemove, {
        singular: 'credit card',
        onRemove,
        text: `${this.$t('confirmation.confirm_delete_creditcard')} 
        <strong>${creditCard.card.brand}</strong> 
        ${this.$t('shared_modal-remove.confirm_delete_creditcard_with_last_4_digits')} 
        <strong>${creditCard.card.last4}</strong>?`,
      });
    },

    onSaved(isEdit) {
      this.$notice.show('notices.credit_card_saved');
      if (!isEdit) {
        this.loadCreditCards(true);
      }
    },

    onRemoved() {
      this.$notice.show('notices.credit_card_removed');
      this.loadCreditCards(true);
    },

    loadPage(page) {
      this.page = page;
      this.loadCreditCards(true);
    },

    async loadCreditCards() {
      this.isLoading = true;
      const filter = this.makeFilter();
      await CreditCardApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false);
    },

    makeFilter(extra) {
      const filter = this.filter.toJSON();
      const { page, itemsPerPage } = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra);
      }

      return filter;
    },

    processData(data) {
      const { meta, creditCards } = data;
      this.creditCards = creditCards;
      this.totalItems = meta.total;
    },
  },
};
</script>

<template>
  <div class="Page">
    <page-meta
      type="credit-cards_overview.title"
      
      :filter="filter"
      :total-items="totalItems"
      :showAddButton="false"
      :num-pages="numPages"
      :page="page"
      :loadPage="loadPage"
    >
      <template slot="actions">
        <div class="Contracts-Button-Wrap">
          <button :disabled="!stripeConnected" @click="add" class="Button--cta">
            {{ $t('global.add') }} {{$t('credit-cards_overview.title')}}
          </button>
        </div>
      </template>

      <template slot="warning" v-if="!stripeConnected">
        <div class="Message-Title">
          <router-link to="/integrations/stripe">{{$t('credit-cards_overview.disabled_stripe')}}</router-link>
          {{$t('credit-cards_overview.disabled_to_enable')}}
        </div>
      </template>

      <template slot="settings">
        <setting-options>
          <li>
            <a @click="$router.push('/workspace/assets/assetTypes')">
              <i class="Icon-Base">widgets</i>{{$t('shared_settings-options.asset_types')}}
            </a>
          </li>
          <li>
            <a @click="$router.push({ name: 'integrations' })">
              <i class="Icon-Base">device_hub</i>{{$t('shared_settings-options.integrations')}}
            </a>
          </li>
          <hr />
          <li>
            <a :href="$t('shared_settings-options.help_creditcards_link')" target="_blank">
              <i class="Icon-Base">help</i>{{$t('shared_settings-options.help_creditcards')}} <i class="Icon-External">launch</i>
            </a>
          </li>
        </setting-options>
      </template>

    </page-meta>

    <div class="PageContents">
      <div class="Container">

        <div class="Table">
          <overview-onboarding experience="creditcards_overview_onboarding">
            <div class="Section-Onboarding--Img">
              <div class="Spacer2XL"></div>
              <i class="Icon-Base">credit_card</i>
              <!-- <img src="@/assets/images/onboard/online_payments.svg" alt="Invoices Onboarding"> -->
            </div>
            <div class="Section-Onboarding--Content">
              <h2 class="OnboardingTitle">{{$t('onboarding_credit_cards.credit_cards_title')}}</h2>
              <div class="OnboardingIntro">
                {{$t('onboarding_credit_cards.credit_cards_intro')}}
              </div>
              <p>
                <router-link to="/integrations/stripe">{{$t('onboarding_credit_cards.credit_cards_integration_link')}}</router-link>
                {{$t('onboarding_credit_cards.credit_cards_regular_text')}}
              </p>
              <i18n path="onboarding_credit_cards.read_more.text" tag="p" class="Section-Onboarding--Guidelink">
                <template v-slot:url>
                  <a :href="$t('onboarding_credit_cards.read_more.link')" target="_blank"
                    :title="$t('onboarding_credit_cards.read_more.title')">
                    {{$t('onboarding_credit_cards.read_more.urltext')}}</a><i class="Icon-External">launch</i>
                </template>
              </i18n>
            </div>
          </overview-onboarding>
          <div class="Table-header">
            <div class="Table-col--M">
              <sort :sort="filter.sort" field="contact.name" @toggle="filter.update('sort', $event.sort)">
                {{$t('credit-cards_overview.client')}}</sort>
            </div>
            <div class="Table-col--M Hide-BreakPointMedium">
              {{ $t('credit-cards_overview.name_on_card')}}
            </div>
            <div class="Table-col--S">
              <sort :sort="filter.sort" field="dueDate" @toggle="filter.update('sort', $event.sort)">
                {{$t('credit-cards_overview.card_id')}}</sort>
            </div>
            <div class="Table-col--S Hide-BreakPointMedium">
              <sort :sort="filter.sort" field="date" @toggle="filter.update('sort', $event.sort)">
                {{$t('credit-cards_overview.expiry_date')}}</sort>
            </div>
            <div class="Table-col--M Hide-BreakPointMedium">
              <sort :sort="filter.sort" field="automaticPaymentEnabled" @toggle="filter.update('sort', $event.sort)">
                {{$t('credit-cards_overview.payment_settings')}}</sort>
            </div>
            <div class="Table-header--spacer" />
          </div>

          <div v-if="isLoading && !hasItems">
            <spinner class="Spinner--general" />
          </div>
          <div v-if="!isLoading && !hasItems" class="Table-noResults">
            {{$t('global.table_no_results') }}
          </div>

          <div v-if="hasItems">
            <div v-for="creditCard in creditCards" :key="creditCard.id" class="Table-row">
              <div class="Table-col--M Table-col--primary" @click="edit(creditCard)">
                {{creditCard.contact?.name}}
              </div>
              <div class="Table-col--M Hide-BreakPointMedium">
                {{creditCard.nameOnCard}}
              </div>
              <div class="Table-col--S">
                <img v-if="creditCard.card.brand == 'Visa'" src="@/assets/images/cards/visa.svg" title="Visa" alt="Visa"
                  class="CardIcon" />
                <img v-if="creditCard.card.brand == 'JCB'" src="@/assets/images/cards/jcb.svg" title="JCB" alt="JCB"
                  class="CardIcon" />
                <img v-if="creditCard.card.brand == 'Discover'" src="@/assets/images/cards/discover.svg"
                  title="Discover" alt="Discover" class="CardIcon" />
                <img v-if="creditCard.card.brand == 'American Express'" src="@/assets/images/cards/americanexpress.svg"
                  title="American Express" alt="American Experess" class="CardIcon" />
                <img v-if="creditCard.card.brand == 'Diners Club'" src="@/assets/images/cards/diners.svg"
                  title="Diners Club" alt="Diners Club" class="CardIcon" />
                <img v-if="creditCard.card.brand == 'China Union Pay'" src="@/assets/images/cards/chinaunionpay.svg"
                  title="China Union Pay" alt="China Union Pay" class="CardIcon" />
                **** {{creditCard.card.last4}}
              </div>
              <div class="Table-col--S Hide-BreakPointMedium">
                {{creditCard.card.exp_month + ' / ' + creditCard.card.exp_year || '--/--'}}
              </div>
              <div class="Table-col--M Hide-BreakPointMedium">
                <div v-if="creditCard.automaticPaymentEnabled" class="TableIcon-Item">
                  <i class="Icon-Base--info">smart_toy</i>
                  Auto-pay
                </div>
                <div v-else class="TableIcon-Item">
                  <i class="Icon-Base--warning">ads_click</i>
                  Click-to-pay
                </div>
              </div>
              <div class="Table-col--options">
                <setting-options :options="['edit', 'remove']" @edit="edit(creditCard)" @remove="remove(creditCard)">
                </setting-options>
              </div>
            </div>
          </div>
        </div>

        <pagination
          :show-all="totalItems < 500"
          :page="page"
          :num-pages="numPages"
          @change="loadPage($event.page)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.TableIcon-Item {
  display: flex;
  align-items: center;
  gap: $spacingXS;
}
</style>