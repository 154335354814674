import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import cfg from '@/services/cfg';
import AuthApi from '@/api/auth.api';
import Token from '@/models/token.model';
import EventEmitter from '@/classes/event-emitter';
import Engage from '@/services/engage';

/**
 * Create mixin to load the authentication service as dependency in components
 */
Vue.mixin({
  beforeCreate() {
    const options = this.$options;
    if (options.auth) {
      this.$auth = options.auth;
    }
    else if (options.parent && options.parent.$auth) {
      this.$auth = options.parent.$auth;
    }
  },
});

class AuthService extends EventEmitter {
  async loadToken() {

    //Already have token
    if (this.token) {
      return;
    }

    //Check if there is an existing token
    this.token = Token.existing();
  }

  /**
   * Check if we're currently authenticated (e.g. have a token)
   */
  hasToken() {
    return (this.token && this.token.accessToken);
  }

  getToken() {
    //No token
    if (!this.token) {
      return '';
    }

    return this.token.accessToken;
  }

  setToken(accessToken) {
    this.token = Token.use(accessToken);
  }

  clearToken() {
    this.token = null;
    Token.clear();
  }

  isTokenExpired() {
    return (this.token && this.token.isExpired());
  }

  /**
   * Check if access token is about to expire
   */
  isTokenExpiring(offset = 60) {
    return (this.token && this.token.isExpiring(offset));
  }

  getQueryString() {
    //No access token
    if (!this.token || !this.token.accessToken) {
      return '';
    }

    //Return header
    return `?access_token=${this.token.accessToken}`;
  }

  /**************************************************************************
   * Authentication flow control
   ***/

  async loginWithCredentials(email, password, redirect = cfg.auth.homeRoute, fromFrill) {

    //Obtain token from server
    this.token = await Token.obtain('password', {email, password});
    await this.onAuthenticated(redirect, fromFrill);
  }

  /**
   * Login with existing access token (from oAuth flow)
   */
  async loginWithToken(accessToken, redirect = cfg.auth.homeRoute) {

    //Set token
    this.token = Token.use(accessToken);

    await this.onAuthenticated(redirect);
  }

  async loginWithOAuth(provider, action = 'login', state = {}) {

    //If object, get ID
    if (typeof provider === 'object') {
      provider = provider.id;
    }

    //Always append action to state
    state.action = action;

    //Create serialized params from state object
    const params = Object
      .entries(state)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    //Determine path
    const base = cfg.api.baseUrl;
    const path = this.getOAuthPath(action);
    const qs = params ? `?${params}` : '';


    //Wrap in promise
    return Promise
      .resolve()
      .then(() => {
        document.location.href = `${base}/${path}/${provider}${qs}`;
      });
  }

  async logout(isAutomatic = false) {
    this.token = Token.clear();

    //Call unauthenticated handler direclty
    this.onUnauthenticated();

    //Revoke refresh token on server if manually logged out
    if (!isAutomatic) {
      await AuthApi.revoke();
    }
  }

  /**
   * Refresh access token
   */
  async refresh() {
    //Return existing refresh promise if already refreshing
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    //Refresh
    return this.refreshPromise = Token
      .obtain('refreshToken')
      .then(token => this.token = token)
      .catch(() => this.clearToken())
      .finally(() => this.refreshPromise = null);
  }

  /**
   * Get oAuth path for given action
   */
  getOAuthPath(action) {
    switch (action) {
      case 'register':
        return 'user/register';
      case 'login':
      case 'connect':
      default:
        return 'auth';
    }
  }

  /**************************************************************************
   * Auth status change handlers
   ***/

  async onAuthenticated(redirect, fromFrill) {

    //Load user and account
    await Promise.all([
      store.dispatch('session/loadUser'),
      store.dispatch('session/loadAccount'),
    ]);

    //Emit authenticated event
    // MHJB: I don't think this is used
    this.emit('authenticated');

    const { token: { payload: { userId }}} = this;

    Engage.track(userId, 'login');

    const user = store.getters['session/me'];
    const account = store.getters['session/account'];
    const role = user.roles.some(role => role.name === 'Admin') ? 'Admin' : 'User';
    if (window.Beacon) {
      window.Beacon('identify', {
        email: user.email,
        name: user.name,
        company: account.name,
        role,
      });
    }

    if (fromFrill) {
      const frillSSOToken = store.getters['session/frillSSOToken'];
      document.location.href = redirect + '?ssoToken=' + frillSSOToken;
    }

    //Redirect if needed
    else if (redirect) {
      router.push({name: redirect});
    }
  }

  async onUnauthenticated() {

    //Redirect back to login unless allowed to be here
    if (router.currentRoute.matched.some(route => route.meta.auth)) {
      await router.push({name: cfg.auth.loginRoute});
    }

    //Emit unauthenticated event
    this.emit('unauthenticated');
    document.getElementsByTagName('body')[0].classList.remove('authenticated');

    //Unload user and account after navigated away
    store.dispatch('session/unloadUser');
    store.dispatch('session/unloadAccount');
  }
}

/**
 * Export singleton instance
 */
export default new AuthService();
