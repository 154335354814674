<script>
export default {
  props: {
    option: {
      type: Object,
      default: () => ({}),
    },
    labelBy: {
      type: String,
    },
    specialDisplay: {
      type: String,
    },
  },
};
</script>

<template>
  <div v-if="specialDisplay == 'asset'">
    <div class="OptionItem--asset">
      <div class="ItemM">
        <icon
          class="Icon-Item"
          :name="option.assetType.icon || option.assetType.name"
        />
        <div class="Item-TextWrap">
          <div class="Item-Headline">{{ option.name }}
          </div>
          <div class="Description">
            {{ option.assetType.name }}
          </div>
        </div>
      </div>
      <div v-if="option.contact?.name" class="Lookup-Title--owner">
        <div class="ItemS">
          <i class="Icon-Base--filled">people</i> 
          <span class="Lookup-Title--text">{{ option.contact?.name}}</span>
        </div>
      </div>
      <span v-else class="Lookup-Title--noOwner">{{ $t('shared_decorative-option.no_owner')}}</span>
    </div>
  </div>
  <div v-else>
    {{ option[labelBy] }}
  </div>
</template>

<style lang="scss">

.ItemM {
  flex: 1;
}

.OptionItem--asset {
  display: flex;
  gap: $spacingL;
  .Lookup-Title--owner,
  .Lookup-Title--noOwner  {
    flex: 0.6;
    font-weight: $fontWeightNormal;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    hyphens: auto;
    .Lookup-Title--text  {
      white-space: pre-wrap;
      overflow-wrap: break-word;
      hyphens: auto;
    }
  }
  .Lookup-Title--noOwner {
    color: $colorValueNotSet;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    hyphens: auto;
  }
}

</style>