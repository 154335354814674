import Vue from 'vue';
import router from '@/router';
import cfg from '@/services/cfg';
import auth from '@/services/auth';
import modal from '@/services/modal';
import sentry from '@/services/sentry';
import ErrorModal from '@/components/shared/modal-error';

import {
  ResponseError,
  NetworkError,
  ClientError,
  ServerError,
  ExistsError,
  TimeoutError,
  ValidationError,
  NotFoundError,
  NotAuthorizedError,
  NotAuthenticatedError,
  ServiceUnavailableError,
} from '@/errors/index';

/**
 * Create mixin to load the errors service as dependency in components
 */
Vue.mixin({
  beforeCreate() {
    const options = this.$options;
    if (options.errors) {
      this.$err = options.errors;
    }
    else if (options.parent && options.parent.$err) {
      this.$err = options.parent.$err;
    }
  },
});

/**
 * Prevent logging of already processed unhandled rejection in console
 */
window.addEventListener('unhandledrejection', event => {
  if (event.reason && event.reason.ignoreUnhandledRejection) {
    event.preventDefault();
  }
});

class ErrorsService {
  process(error) {
    if (error.isProcessed) {
      return;
    }

    error.isProcessed = true;

    // Log if configured
    if (cfg.errors.log) {
      console.error(error);
    }

    sentry.captureException(error);

    // Handle auth errors
    if (error instanceof NotAuthenticatedError) {
      modal.hide();
      return auth.logout(true);
    }

    // Show error modal
    modal.show(
      ErrorModal,
      {error},
      {maxWidth: 600}
    );
  }

  /**
   * Get error class for response errors
   */
  getClass(response) {

    //Network error
    if (typeof response === 'undefined') {
      return NetworkError;
    }

    //Get data
    const {status} = response;

    //Unauthenticated errors
    if (status === 401) {
      return NotAuthenticatedError;
    }

    //Unauthorized errors
    else if (status === 403) {
      return NotAuthorizedError;
    }

    //Not found errors
    else if (status === 404) {
      return NotFoundError;
    }

    //Exists errors
    else if (status === 409) {
      return ExistsError;
    }

    //Validation errors
    else if (status === 422) {
      return ValidationError;
    }

    //Service unavailable errors
    else if (status === 503) {
      return ServiceUnavailableError;
    }

    //Request timeout
    else if (status === 408 || status === 504) {
      return TimeoutError;
    }

    //Generic client errors
    else if (status >= 400 && status <= 499) {
      return ClientError;
    }

    //Server errors
    else if (status >= 500 && status <= 599) {
      return ServerError;
    }

    //Generic response errors
    return ResponseError;
  }
}

/**
 * Export singleton instance
 */
export default new ErrorsService();
