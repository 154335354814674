<script>
import { mapState } from 'vuex';
import AuditLogApi from '@/api/audit-log.api';
import IntegrationTypes from '@/constants/integration-types';

export default {
  data() {
    return {
      logs: [],
      AuditLogsLoading: true,
    };
  },

  created() {
    this.loadLogs();
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
    }),

    timezone() {
      return this.account?.timezone;
    },
  },

  methods: {
    async loadLogs() {
      this.AuditLogsLoading = true;

      await AuditLogApi
        .recent()
        .then(data => this.logs = data)
        .finally(() => this.AuditLogsLoading = false);
    },

    update_title(logItem) {
      if (!logItem) {
        return 'n/a';
      }
      if (logItem.model === 'Integration') {
        return IntegrationTypes[logItem.type];
      }
      if (logItem.model === 'Asset') {
        return logItem.name;
      }
      return logItem.name || (logItem.model + (logItem.type ? ' ' + logItem.type : '')) || 'n/a';
    },
  },
};
</script>

<template>
  <div class="PanelSection">
    <div class="Panel-Updates">
      <div class="PanelHeader">
        <div class="PanelHeader-title">
          {{ $t('audit_log.title') }}
        </div>
      </div>
      <div class="Updates">
        <div v-if="AuditLogsLoading">
          <div class="Recently-Updated--spinner">
            <spinner class="Spinner--general" />
          </div>
        </div>
      <div v-for="log in logs" :key="log.id">
        <router-link :to="log.editRoute" :class="(log.editRoute.length === 0 ? 'disabled' : '') + ' Update--link'">
          <div class="Update">
            <div class="Update--content">
              <div class="Update--title">
                <icon class="Icon-Update" :name="log.icon" />
                <div class="Update--titleText">{{ update_title(log.item) }}
                <span :class="(log.displayModelClass)"><span class="Update-Pipe">|</span>{{ $t(log.displayModel) }}</span></div>
              </div>
              <div class="Update--Meta" v-html="log.oneLinerRecentUpdates(timezone)" />
            </div>
          </div>
        </router-link>
      </div>
    </div>
    </div>
  </div>
</template>
