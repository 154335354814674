<script>
import CashflowMenu from './settings-sidemenu';

export default {

  components: { CashflowMenu },
};
</script>

<template>
  <div class="Page">
    <div class="InnerPage-Wrap--sidemenu">
      <div class="Menu--col">
        <cashflow-menu />
      </div>
      <div class="Content--mediumWidth">
        <h1 class="PageTitle">{{$t('settings-overview.title')}}</h1>
        <div class="SpacerXL"></div>
        <div class="Settings-Grid">
          <div class="SettingsOverview--section">
            <h2>{{$t('global.workspace')}}</h2>
            <div class="Content-LinkWrap">
              <router-link class="Content-Link" to="/workspace/assets/assetTypes">{{$t('settings_workspace.menu_asset_types')}}</router-link>  <i class="Icon-External">launch</i>
            </div>
          </div>

          <div class="SettingsOverview--section">
            <h2>{{$t('settings_cashflow.settings_overview_title')}}</h2>
            <div class="Content-LinkWrap">
              <router-link class="Content-Link" to="/settings/tax">{{$t('settings_cashflow.menu_tax')}}</router-link>
            </div>
            <div class="Content-LinkWrap">
              <router-link class="Content-Link" to="/settings/invoices">{{$t('settings_cashflow.menu_invoices')}}</router-link>
            </div>
          </div>

          <div class="SettingsOverview--section">
            <h2>{{$t('settings_directory.settings_overview_title')}}</h2>
            <div class="Content-LinkWrap">
              <router-link class="Content-Link" to="/directory/users">{{$t('settings_directory.user_list')}}</router-link>  <i class="Icon-External">launch</i>
            </div>
          </div>

          <div class="SettingsOverview--section">
            <h2>{{$t('global.account')}}</h2>
            <div class="Content-LinkWrap">
              <router-link class="Content-Link" to="/account">{{$t('settings_account.settings_overview_account')}}</router-link>
            </div>
            <div class="Content-LinkWrap">
              <router-link class="Content-Link" to="/account/billing">{{$t('settings_account.settings_overview_plan_billing')}}</router-link>
            </div>
            <div class="Content-LinkWrap">
              <router-link class="Content-Link" to="/account/billing-history">{{$t('settings_account.settings_overview_billing_history')}}</router-link>
            </div>
          </div>

         <div class="SettingsOverview--section">
            <h2>{{$t('global.user_settings')}}</h2>
            <div class="Content-LinkWrap">
              <router-link class="Content-Link" to="/profile">{{$t('global.my_profile')}}</router-link>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.Settings-Grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media (min-width: $breakPointMedium) {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}
.Content-LinkWrap {
  width: 100%;
  margin: $spacing2XL 0;
}
.SettingsOverview--section {
  width: 100%;
  h2 {
    padding: 0;
    white-space: nowrap;
  }
}
  .Content-LinkWrap {
    width: 100%;
    white-space: nowrap;
    margin: $spacing2XL 0;
  }
  .SettingsOverview--section {
    h2 {
      padding: 0;
    }
  }
</style>
