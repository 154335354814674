<script>
import ModalView from '@/components/shared/modal-view';
import DisplayField from '@/components/shared/display-field';
import { mapState } from 'vuex';

export default {
  components: {
    ModalView,
    DisplayField,
  },

  data() {
    return {
      offerings: [],
    };
  },

  props: {
    item: { type: Object },
    onEdit: { type: Function },
    onArchive: { type: Function },
    onSave: { type: Function },
    fields: {
      type: String,
      default: 'name,invoicePrice',
    },
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
    }),

    contactName: function() {
      return this.item.contact?.name;
    },
  },

  methods: {
    edit() {
      this
        .onEdit(true) // true = editOwner
        .then(() => this.$emit('close'));
    },

    ext_url(assetType) {
      const assetTypeUrls = {
        'Webflow website': 'https://webflow.com/dashboard',
        'DNSimple domain': 'https://dnsimple.com/dashboard',
        'DigitalOcean Droplet': 'https://cloud.digitalocean.com/projects',
        'DigitalOcean Domain': 'https://cloud.digitalocean.com/projects',
      }
      return assetTypeUrls[assetType] || '';
    }
  },
};
</script>

<template>
  <modal-view
    :singular="item.name"
    :icon="item?.assetType?.icon || 'widgets'"
    :byline="item?.assetType?.name"
    :on-edit="item.archived ? null : onEdit"
    :on-archive="item.archived || item.contracts?.length > 0 ? null : onArchive"
    :saveable="false"
    :item="item"
    @close="$emit('close')"
  >
    <div class="Section">
      <div class="Modal--item">
          <label class="Label">{{$t('assets_modals_view.owner')}}</label>
          <div class="Modal-Value">
            <modal-link
              v-if="contactName"
              :to="`/directory/clients/view/${item.contact?.id}`"
              :from="`/workspace/assets/view/${item.id}`"
              fromName="asset"
            >
              {{contactName}}
            </modal-link>
            <span v-else @click="edit" class="Modal-Value--exception">
              <i class="Icon-Base--warning">error_outline</i>
              <a @click="edit">{{$t('assets_modals_view.add_owner')}}</a>
            </span>

          </div>
        </div>


      <div class="Modal--item">
        <label class="Label">{{$t('assets_modals_view.description')}}</label>
        <div class="Modal-Value-description" v-if="item.description">
          {{item.description}}
        </div>
        <div class="Modal-Value-description Modal-Value--notSet" v-else >
          {{$t('assets_modals_view.no_description')}}
        </div>
      </div>
    </div>

      <div class="Section" v-if="item.displayFields && item.displayFields.length">
        <div class="Modal-Panel--warning">
          <div class="Panel-Headline">
              <a
                v-if="ext_url(item.assetType.name)"
                :href="ext_url(item.assetType.name)"
                target="_blank" class="Panel-Headline--link"
                >
                {{item.assetType.name}}
              </a><i class="Icon-External">launch</i>
          </div>
          <div class="SpacerL"></div>
          <div class="DisplayFields">
            <template v-for="field in item.displayFields">
              <display-field
                :key="field.key"
                v-bind="field"
              />
            </template>
          </div>
        </div>
      </div>

      <div class="Section" v-if="item.contracts?.length">
        <div class="SpacerXL"></div>
        <div class="SpacerL"></div>
        <div class="LabelButton-Wrap">
          <label class="Tally-Stats Tally-Stat--exists">
            {{ $t('assets_modals_view.asset_has') }}
            <span class="Tally-Number" :class="{'Tally-Stat--nil': item.contracts?.length === 0}">
              {{ item.contracts?.length }}</span> {{ $tc('global.service_plural', item.contracts?.length) }}
          </label>
          <modal-link
            v-if="account.readyForContracts"
            class="Button--cta Button--small"
            :to="`/workspace/services/add?assetId=${item.id}&clientId=${item.contact?.id}&clientName=${item.contact?.name}`"
            :from="`/workspace/assets/view/${item.id}`"
            fromName="asset"
          >
            {{$t('assets_modals_view.add_contract')}}
          </modal-link>
        </div>
        <div class="SpacerL"></div>
        <div v-if='item.contracts?.length'>
          <div class="SpacerM"></div>
          <div v-for="(contract, index) in item.contracts"
            :key="index"
            class="Modal-Panel"
          >
            <div class="ItemM">
              <div class="Item-Icon">
                <icons-services :period="contract.recurrence?.interval" />
              </div>
              <div class="Item-TextWrap">
                <modal-link 
                  :to="`/workspace/services/view/${contract.id}`"
                  :from="`/workspace/assets/view/${item.id}`"
                  fromName="asset"
                  class="Item-Headline"
                >
                  {{contract.name}}
                </modal-link>
                <div class="Description">
                  {{$t('global.service')}}, {{ contract.totalValue | currency }} + tax,
                  <span v-if="contract?.recurrence?.ends === 'never'">ongoing</span>
                  <span v-else>until {{ contract.recurrence?.endDate | moment('ll') }}</span>
                </div>
              </div>
              <div class="Panel-ContractRevenue">
                <div>{{ contract.totalValue | currency }} + {{$t('global.tax')}}</div>
                <div class="Description">{{ contract?.recurrence?.interval | recurrenceInterval }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </modal-view>
</template>

<style lang="scss">

.Asset--brand {
  text-transform: capitalize;
}

.LabelButton-Wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: $gapXL;

  .Label,
  .Label--caps {
    margin-bottom: 0;
  }

  .Button--small {
    margin-left: auto;
    margin-bottom: 0;
    padding: 5px 25px;
  }

}

.DisplayFields {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: $gapL;
}

</style>
